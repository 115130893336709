import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _279d08ad = () => interopDefault(import('../pages/ds/index.vue' /* webpackChunkName: "pages/ds/index" */))
const _2a28092e = () => interopDefault(import('../pages/ds/biz.vue' /* webpackChunkName: "pages/ds/biz" */))
const _e89e2d72 = () => interopDefault(import('../pages/ds/intro.vue' /* webpackChunkName: "pages/ds/intro" */))
const _522b424f = () => interopDefault(import('../pages/ds/member.vue' /* webpackChunkName: "pages/ds/member" */))
const _33aa007c = () => interopDefault(import('../pages/bp/bpa/SCR_BPA_0001.vue' /* webpackChunkName: "pages/bp/bpa/SCR_BPA_0001" */))
const _428118f5 = () => interopDefault(import('../pages/bp/bpa/SCR_BPA_0001A.vue' /* webpackChunkName: "pages/bp/bpa/SCR_BPA_0001A" */))
const _340ca503 = () => interopDefault(import('../pages/bp/bpa/SCR_BPA_0008.vue' /* webpackChunkName: "pages/bp/bpa/SCR_BPA_0008" */))
const _9c1eafd0 = () => interopDefault(import('../pages/cm/cma/SCR_CMA_0001.vue' /* webpackChunkName: "pages/cm/cma/SCR_CMA_0001" */))
const _365eb20f = () => interopDefault(import('../pages/cs/csh/SCR_CSH_0000.vue' /* webpackChunkName: "pages/cs/csh/SCR_CSH_0000" */))
const _366cc990 = () => interopDefault(import('../pages/cs/csh/SCR_CSH_0001.vue' /* webpackChunkName: "pages/cs/csh/SCR_CSH_0001" */))
const _367ae111 = () => interopDefault(import('../pages/cs/csh/SCR_CSH_0002.vue' /* webpackChunkName: "pages/cs/csh/SCR_CSH_0002" */))
const _3688f892 = () => interopDefault(import('../pages/cs/csh/SCR_CSH_0003.vue' /* webpackChunkName: "pages/cs/csh/SCR_CSH_0003" */))
const _36971013 = () => interopDefault(import('../pages/cs/csh/SCR_CSH_0004.vue' /* webpackChunkName: "pages/cs/csh/SCR_CSH_0004" */))
const _216fc664 = () => interopDefault(import('../pages/cs/csi/SCR_CSI_0001.vue' /* webpackChunkName: "pages/cs/csi/SCR_CSI_0001" */))
const _60f0abc9 = () => interopDefault(import('../pages/cs/csk/SCR_CSK_0000.vue' /* webpackChunkName: "pages/cs/csk/SCR_CSK_0000" */))
const _60fec34a = () => interopDefault(import('../pages/cs/csk/SCR_CSK_0001.vue' /* webpackChunkName: "pages/cs/csk/SCR_CSK_0001" */))
const _610cdacb = () => interopDefault(import('../pages/cs/csk/SCR_CSK_0002.vue' /* webpackChunkName: "pages/cs/csk/SCR_CSK_0002" */))
const _19da1688 = () => interopDefault(import('../pages/cs/csl/SCR_CSL_0001.vue' /* webpackChunkName: "pages/cs/csl/SCR_CSL_0001" */))
const _4587b728 = () => interopDefault(import('../pages/cs/csl/SCR_CSL_0001_P1.vue' /* webpackChunkName: "pages/cs/csl/SCR_CSL_0001_P1" */))
const _4595cea9 = () => interopDefault(import('../pages/cs/csl/SCR_CSL_0001_P2.vue' /* webpackChunkName: "pages/cs/csl/SCR_CSL_0001_P2" */))
const _45a3e62a = () => interopDefault(import('../pages/cs/csl/SCR_CSL_0001_P3.vue' /* webpackChunkName: "pages/cs/csl/SCR_CSL_0001_P3" */))
const _1a045d0b = () => interopDefault(import('../pages/cs/csl/SCR_CSL_0004.vue' /* webpackChunkName: "pages/cs/csl/SCR_CSL_0004" */))
const _0936b9d6 = () => interopDefault(import('../pages/cs/csl/SCR_CSL_0004_I.vue' /* webpackChunkName: "pages/cs/csl/SCR_CSL_0004_I" */))
const _087170c8 = () => interopDefault(import('../pages/cs/csl/SCR_CSL_0004_P.vue' /* webpackChunkName: "pages/cs/csl/SCR_CSL_0004_P" */))
const _5ab15b76 = () => interopDefault(import('../pages/cs/csm/SCR_CSM_0000.vue' /* webpackChunkName: "pages/cs/csm/SCR_CSM_0000" */))
const _5a952c74 = () => interopDefault(import('../pages/cs/csm/SCR_CSM_0001.vue' /* webpackChunkName: "pages/cs/csm/SCR_CSM_0001" */))
const _5a78fd72 = () => interopDefault(import('../pages/cs/csm/SCR_CSM_0002.vue' /* webpackChunkName: "pages/cs/csm/SCR_CSM_0002" */))
const _3bbb8d55 = () => interopDefault(import('../pages/cs/csn/SCR_CSN_0001_P.vue' /* webpackChunkName: "pages/cs/csn/SCR_CSN_0001_P" */))
const _21ea3dad = () => interopDefault(import('../pages/ds/dsa/chatbot.vue' /* webpackChunkName: "pages/ds/dsa/chatbot" */))
const _719b5730 = () => interopDefault(import('../pages/ds/dsa/SCR_MBC_0050_02_1.vue' /* webpackChunkName: "pages/ds/dsa/SCR_MBC_0050_02_1" */))
const _3968a2fa = () => interopDefault(import('../pages/ds/dsa/SCR_MBC_0113.vue' /* webpackChunkName: "pages/ds/dsa/SCR_MBC_0113" */))
const _48f1c56d = () => interopDefault(import('../pages/ds/dsa/SCR_MBC_0113_01.vue' /* webpackChunkName: "pages/ds/dsa/SCR_MBC_0113_01" */))
const _48ffdcee = () => interopDefault(import('../pages/ds/dsa/SCR_MBC_0113_02.vue' /* webpackChunkName: "pages/ds/dsa/SCR_MBC_0113_02" */))
const _490df46f = () => interopDefault(import('../pages/ds/dsa/SCR_MBC_0113_03.vue' /* webpackChunkName: "pages/ds/dsa/SCR_MBC_0113_03" */))
const _491c0bf0 = () => interopDefault(import('../pages/ds/dsa/SCR_MBC_0113_04.vue' /* webpackChunkName: "pages/ds/dsa/SCR_MBC_0113_04" */))
const _492a2371 = () => interopDefault(import('../pages/ds/dsa/SCR_MBC_0113_05.vue' /* webpackChunkName: "pages/ds/dsa/SCR_MBC_0113_05" */))
const _68dbc96b = () => interopDefault(import('../pages/ds/dsa/videoTest.vue' /* webpackChunkName: "pages/ds/dsa/videoTest" */))
const _1c26fc1a = () => interopDefault(import('../pages/ds/dst/SCR_DST_0015.vue' /* webpackChunkName: "pages/ds/dst/SCR_DST_0015" */))
const _38b0b837 = () => interopDefault(import('../pages/ds/keybiz/keyVisualList.vue' /* webpackChunkName: "pages/ds/keybiz/keyVisualList" */))
const _5d861816 = () => interopDefault(import('../pages/ds/keybiz/pdNotification.vue' /* webpackChunkName: "pages/ds/keybiz/pdNotification" */))
const _c9cb202a = () => interopDefault(import('../pages/ds/rank/popular.vue' /* webpackChunkName: "pages/ds/rank/popular" */))
const _5d2b03b2 = () => interopDefault(import('../pages/ds/rank/rePurchase.vue' /* webpackChunkName: "pages/ds/rank/rePurchase" */))
const _3980d094 = () => interopDefault(import('../pages/ds/rank/review.vue' /* webpackChunkName: "pages/ds/rank/review" */))
const _6f267e8a = () => interopDefault(import('../pages/ev/evc/SCR_EVC_0001.vue' /* webpackChunkName: "pages/ev/evc/SCR_EVC_0001" */))
const _6eee2086 = () => interopDefault(import('../pages/ev/evc/SCR_EVC_0003.vue' /* webpackChunkName: "pages/ev/evc/SCR_EVC_0003" */))
const _6e45067a = () => interopDefault(import('../pages/ev/evc/SCR_EVC_0009.vue' /* webpackChunkName: "pages/ev/evc/SCR_EVC_0009" */))
const _6bbccd4c = () => interopDefault(import('../pages/ev/evc/SCR_EVC_0011.vue' /* webpackChunkName: "pages/ev/evc/SCR_EVC_0011" */))
const _6b846f48 = () => interopDefault(import('../pages/ev/evc/SCR_EVC_0013.vue' /* webpackChunkName: "pages/ev/evc/SCR_EVC_0013" */))
const _6b4c1144 = () => interopDefault(import('../pages/ev/evc/SCR_EVC_0015.vue' /* webpackChunkName: "pages/ev/evc/SCR_EVC_0015" */))
const _6b13b340 = () => interopDefault(import('../pages/ev/evc/SCR_EVC_0017.vue' /* webpackChunkName: "pages/ev/evc/SCR_EVC_0017" */))
const _6adb553c = () => interopDefault(import('../pages/ev/evc/SCR_EVC_0019.vue' /* webpackChunkName: "pages/ev/evc/SCR_EVC_0019" */))
const _fd6fd80e = () => interopDefault(import('../pages/ev/evd/SCR_EVD_0001.vue' /* webpackChunkName: "pages/ev/evd/SCR_EVD_0001" */))
const _fd377a0a = () => interopDefault(import('../pages/ev/evd/SCR_EVD_0003.vue' /* webpackChunkName: "pages/ev/evd/SCR_EVD_0003" */))
const _fcaa8f00 = () => interopDefault(import('../pages/ev/evd/SCR_EVD_0008.vue' /* webpackChunkName: "pages/ev/evd/SCR_EVD_0008" */))
const _fc8e5ffe = () => interopDefault(import('../pages/ev/evd/SCR_EVD_0009.vue' /* webpackChunkName: "pages/ev/evd/SCR_EVD_0009" */))
const _fa0626d0 = () => interopDefault(import('../pages/ev/evd/SCR_EVD_0011.vue' /* webpackChunkName: "pages/ev/evd/SCR_EVD_0011" */))
const _5a9b00aa = () => interopDefault(import('../pages/fb/fba/SCR_FBA_0001.vue' /* webpackChunkName: "pages/fb/fba/SCR_FBA_0001" */))
const _5ab72fac = () => interopDefault(import('../pages/fb/fba/SCR_FBA_0003.vue' /* webpackChunkName: "pages/fb/fba/SCR_FBA_0003" */))
const _5ad35eae = () => interopDefault(import('../pages/fb/fba/SCR_FBA_0005.vue' /* webpackChunkName: "pages/fb/fba/SCR_FBA_0005" */))
const _5bd93f1f = () => interopDefault(import('../pages/fb/fba/SCR_FBA_0005_S1.vue' /* webpackChunkName: "pages/fb/fba/SCR_FBA_0005_S1" */))
const _5ae1762f = () => interopDefault(import('../pages/fb/fba/SCR_FBA_0006.vue' /* webpackChunkName: "pages/fb/fba/SCR_FBA_0006" */))
const _137653e8 = () => interopDefault(import('../pages/fb/fbb/SCR_FBB_0001.vue' /* webpackChunkName: "pages/fb/fbb/SCR_FBB_0001" */))
const _4c1cd3c5 = () => interopDefault(import('../pages/fb/fbb/SCR_FBB_0001T1.vue' /* webpackChunkName: "pages/fb/fbb/SCR_FBB_0001T1" */))
const _3876c64d = () => interopDefault(import('../pages/fb/fbb/SCR_FBB_0001T1B.vue' /* webpackChunkName: "pages/fb/fbb/SCR_FBB_0001T1B" */))
const _4c2aeb46 = () => interopDefault(import('../pages/fb/fbb/SCR_FBB_0001T2.vue' /* webpackChunkName: "pages/fb/fbb/SCR_FBB_0001T2" */))
const _13846b69 = () => interopDefault(import('../pages/fb/fbb/SCR_FBB_0002.vue' /* webpackChunkName: "pages/fb/fbb/SCR_FBB_0002" */))
const _139282ea = () => interopDefault(import('../pages/fb/fbb/SCR_FBB_0003.vue' /* webpackChunkName: "pages/fb/fbb/SCR_FBB_0003" */))
const _13aeb1ec = () => interopDefault(import('../pages/fb/fbb/SCR_FBB_0005.vue' /* webpackChunkName: "pages/fb/fbb/SCR_FBB_0005" */))
const _13d8f86f = () => interopDefault(import('../pages/fb/fbb/SCR_FBB_0008.vue' /* webpackChunkName: "pages/fb/fbb/SCR_FBB_0008" */))
const _151d1506 = () => interopDefault(import('../pages/fb/fbb/SCR_FBB_0010.vue' /* webpackChunkName: "pages/fb/fbb/SCR_FBB_0010" */))
const _1555730a = () => interopDefault(import('../pages/fb/fbb/SCR_FBB_0014.vue' /* webpackChunkName: "pages/fb/fbb/SCR_FBB_0014" */))
const _15638a8b = () => interopDefault(import('../pages/fb/fbb/SCR_FBB_0015.vue' /* webpackChunkName: "pages/fb/fbb/SCR_FBB_0015" */))
const _0a517dcc = () => interopDefault(import('../pages/mb/mba/SCR_MBA_0080.vue' /* webpackChunkName: "pages/mb/mba/SCR_MBA_0080" */))
const _13ebf134 = () => interopDefault(import('../pages/mb/mba/SCR_MBA_0082_01.vue' /* webpackChunkName: "pages/mb/mba/SCR_MBA_0082_01" */))
const _13fa08b5 = () => interopDefault(import('../pages/mb/mba/SCR_MBA_0082_02.vue' /* webpackChunkName: "pages/mb/mba/SCR_MBA_0082_02" */))
const _14082036 = () => interopDefault(import('../pages/mb/mba/SCR_MBA_0082_03.vue' /* webpackChunkName: "pages/mb/mba/SCR_MBA_0082_03" */))
const _141637b7 = () => interopDefault(import('../pages/mb/mba/SCR_MBA_0082_04.vue' /* webpackChunkName: "pages/mb/mba/SCR_MBA_0082_04" */))
const _14244f38 = () => interopDefault(import('../pages/mb/mba/SCR_MBA_0082_05.vue' /* webpackChunkName: "pages/mb/mba/SCR_MBA_0082_05" */))
const _143266b9 = () => interopDefault(import('../pages/mb/mba/SCR_MBA_0082_06.vue' /* webpackChunkName: "pages/mb/mba/SCR_MBA_0082_06" */))
const _14407e3a = () => interopDefault(import('../pages/mb/mba/SCR_MBA_0082_07.vue' /* webpackChunkName: "pages/mb/mba/SCR_MBA_0082_07" */))
const _145cad3c = () => interopDefault(import('../pages/mb/mba/SCR_MBA_0082_09.vue' /* webpackChunkName: "pages/mb/mba/SCR_MBA_0082_09" */))
const _1592b252 = () => interopDefault(import('../pages/mb/mba/SCR_MBA_0082_10.vue' /* webpackChunkName: "pages/mb/mba/SCR_MBA_0082_10" */))
const _09e0c1c4 = () => interopDefault(import('../pages/mb/mba/SCR_MBA_0084.vue' /* webpackChunkName: "pages/mb/mba/SCR_MBA_0084" */))
const _991eb6a0 = () => interopDefault(import('../pages/mb/mba/SCR_MBA_0086_01.vue' /* webpackChunkName: "pages/mb/mba/SCR_MBA_0086_01" */))
const _6b38ffd1 = () => interopDefault(import('../pages/mb/mba/SCR_MBA_0086A.vue' /* webpackChunkName: "pages/mb/mba/SCR_MBA_0086A" */))
const _aa891384 = () => interopDefault(import('../pages/mb/mba/SCR_MBA_0086B_01.vue' /* webpackChunkName: "pages/mb/mba/SCR_MBA_0086B_01" */))
const _aa6ce482 = () => interopDefault(import('../pages/mb/mba/SCR_MBA_0086B_02.vue' /* webpackChunkName: "pages/mb/mba/SCR_MBA_0086B_02" */))
const _6b552ed3 = () => interopDefault(import('../pages/mb/mba/SCR_MBA_0086C.vue' /* webpackChunkName: "pages/mb/mba/SCR_MBA_0086C" */))
const _06cb9d8c = () => interopDefault(import('../pages/mb/mba/SCR_MBA_0091.vue' /* webpackChunkName: "pages/mb/mba/SCR_MBA_0091" */))
const _10118d14 = () => interopDefault(import('../pages/mb/mba/SCR_MBA_0093_01.vue' /* webpackChunkName: "pages/mb/mba/SCR_MBA_0093_01" */))
const _7a566d7b = () => interopDefault(import('../pages/mb/mba/SCR_MBA_0093A_01.vue' /* webpackChunkName: "pages/mb/mba/SCR_MBA_0093A_01" */))
const _7a6484fc = () => interopDefault(import('../pages/mb/mba/SCR_MBA_0093A_02.vue' /* webpackChunkName: "pages/mb/mba/SCR_MBA_0093A_02" */))
const _c9e26e94 = () => interopDefault(import('../pages/mb/mba/SCR_MBA_0093B.vue' /* webpackChunkName: "pages/mb/mba/SCR_MBA_0093B" */))
const _4a18c739 = () => interopDefault(import('../pages/mb/mba/SCR_MBA_0093C_01.vue' /* webpackChunkName: "pages/mb/mba/SCR_MBA_0093C_01" */))
const _4a26deba = () => interopDefault(import('../pages/mb/mba/SCR_MBA_0093C_02.vue' /* webpackChunkName: "pages/mb/mba/SCR_MBA_0093C_02" */))
const _c694ec58 = () => interopDefault(import('../pages/mb/mba/SCR_MBA_0094A.vue' /* webpackChunkName: "pages/mb/mba/SCR_MBA_0094A" */))
const _47b513b1 = () => interopDefault(import('../pages/mb/mba/SCR_MBA_0096_01.vue' /* webpackChunkName: "pages/mb/mba/SCR_MBA_0096_01" */))
const _47c32b32 = () => interopDefault(import('../pages/mb/mba/SCR_MBA_0096_02.vue' /* webpackChunkName: "pages/mb/mba/SCR_MBA_0096_02" */))
const _7f589a4e = () => interopDefault(import('../pages/mb/mba/SCR_MBA_0099_01.vue' /* webpackChunkName: "pages/mb/mba/SCR_MBA_0099_01" */))
const _aa9b86dc = () => interopDefault(import('../pages/mb/mbb/SCR_MBB_0097_01.vue' /* webpackChunkName: "pages/mb/mbb/SCR_MBB_0097_01" */))
const _04632833 = () => interopDefault(import('../pages/mb/mbb/SCR_MBB_0097A.vue' /* webpackChunkName: "pages/mb/mbb/SCR_MBB_0097A" */))
const _04713fb4 = () => interopDefault(import('../pages/mb/mbb/SCR_MBB_0097B.vue' /* webpackChunkName: "pages/mb/mbb/SCR_MBB_0097B" */))
const _944fae02 = () => interopDefault(import('../pages/mb/mbb/SCR_MBB_0098.vue' /* webpackChunkName: "pages/mb/mbb/SCR_MBB_0098" */))
const _94337f00 = () => interopDefault(import('../pages/mb/mbb/SCR_MBB_0099.vue' /* webpackChunkName: "pages/mb/mbb/SCR_MBB_0099" */))
const _68a1de29 = () => interopDefault(import('../pages/mb/mbb/SCR_MBB_0107_02.vue' /* webpackChunkName: "pages/mb/mbb/SCR_MBB_0107_02" */))
const _321b6667 = () => interopDefault(import('../pages/mb/mbb/SCR_MBB_0107A_01.vue' /* webpackChunkName: "pages/mb/mbb/SCR_MBB_0107A_01" */))
const _cc06d974 = () => interopDefault(import('../pages/mb/mbb/SCR_MBB_0107B_01.vue' /* webpackChunkName: "pages/mb/mbb/SCR_MBB_0107B_01" */))
const _5aa44469 = () => interopDefault(import('../pages/mb/mbb/SCR_MBB_0109A_01.vue' /* webpackChunkName: "pages/mb/mbb/SCR_MBB_0109A_01" */))
const _5ab25bea = () => interopDefault(import('../pages/mb/mbb/SCR_MBB_0109A_02.vue' /* webpackChunkName: "pages/mb/mbb/SCR_MBB_0109A_02" */))
const _5ac0736b = () => interopDefault(import('../pages/mb/mbb/SCR_MBB_0109A_03.vue' /* webpackChunkName: "pages/mb/mbb/SCR_MBB_0109A_03" */))
const _7af51d70 = () => interopDefault(import('../pages/mb/mbb/SCR_MBB_0109B_01.vue' /* webpackChunkName: "pages/mb/mbb/SCR_MBB_0109B_01" */))
const _7ad8ee6e = () => interopDefault(import('../pages/mb/mbb/SCR_MBB_0109B_02.vue' /* webpackChunkName: "pages/mb/mbb/SCR_MBB_0109B_02" */))
const _7abcbf6c = () => interopDefault(import('../pages/mb/mbb/SCR_MBB_0109B_03.vue' /* webpackChunkName: "pages/mb/mbb/SCR_MBB_0109B_03" */))
const _d4fb9304 = () => interopDefault(import('../pages/mb/mbc/SCR_MBC_0110.vue' /* webpackChunkName: "pages/mb/mbc/SCR_MBC_0110" */))
const _1bafa333 = () => interopDefault(import('../pages/mb/mbc/SCR_MBC_0110A.vue' /* webpackChunkName: "pages/mb/mbc/SCR_MBC_0110A" */))
const _fe2d5a86 = () => interopDefault(import('../pages/mb/mbc/SCR_MBC_0110A_01.vue' /* webpackChunkName: "pages/mb/mbc/SCR_MBC_0110A_01" */))
const _fe112b84 = () => interopDefault(import('../pages/mb/mbc/SCR_MBC_0110A_02.vue' /* webpackChunkName: "pages/mb/mbc/SCR_MBC_0110A_02" */))
const _2e6b00c8 = () => interopDefault(import('../pages/mb/mbc/SCR_MBC_0110B_01.vue' /* webpackChunkName: "pages/mb/mbc/SCR_MBC_0110B_01" */))
const _2e4ed1c6 = () => interopDefault(import('../pages/mb/mbc/SCR_MBC_0110B_02.vue' /* webpackChunkName: "pages/mb/mbc/SCR_MBC_0110B_02" */))
const _eea5b71e = () => interopDefault(import('../pages/mb/mbc/SCR_MBC_0111_01.vue' /* webpackChunkName: "pages/mb/mbc/SCR_MBC_0111_01" */))
const _1d647bd2 = () => interopDefault(import('../pages/mb/mbc/SCR_MBC_0111A.vue' /* webpackChunkName: "pages/mb/mbc/SCR_MBC_0111A" */))
const _1d729353 = () => interopDefault(import('../pages/mb/mbc/SCR_MBC_0111B.vue' /* webpackChunkName: "pages/mb/mbc/SCR_MBC_0111B" */))
const _4cd1485b = () => interopDefault(import('../pages/mb/mbc/SCR_MBC_0111D_01.vue' /* webpackChunkName: "pages/mb/mbc/SCR_MBC_0111D_01" */))
const _4cdf5fdc = () => interopDefault(import('../pages/mb/mbc/SCR_MBC_0111D_02.vue' /* webpackChunkName: "pages/mb/mbc/SCR_MBC_0111D_02" */))
const _4ced775d = () => interopDefault(import('../pages/mb/mbc/SCR_MBC_0111D_03.vue' /* webpackChunkName: "pages/mb/mbc/SCR_MBC_0111D_03" */))
const _d4c33500 = () => interopDefault(import('../pages/mb/mbc/SCR_MBC_0112.vue' /* webpackChunkName: "pages/mb/mbc/SCR_MBC_0112" */))
const _d4a705fe = () => interopDefault(import('../pages/mb/mbc/SCR_MBC_0113.vue' /* webpackChunkName: "pages/mb/mbc/SCR_MBC_0113" */))
const _7f5ea9e4 = () => interopDefault(import('../pages/mb/mbc/SCR_MBC_0114_01.vue' /* webpackChunkName: "pages/mb/mbc/SCR_MBC_0114_01" */))
const _7f427ae2 = () => interopDefault(import('../pages/mb/mbc/SCR_MBC_0114_02.vue' /* webpackChunkName: "pages/mb/mbc/SCR_MBC_0114_02" */))
const _d46ea7fa = () => interopDefault(import('../pages/mb/mbc/SCR_MBC_0115.vue' /* webpackChunkName: "pages/mb/mbc/SCR_MBC_0115" */))
const _2831d7ed = () => interopDefault(import('../pages/mb/mbc/SCR_MBC_0115_01.vue' /* webpackChunkName: "pages/mb/mbc/SCR_MBC_0115_01" */))
const _283fef6e = () => interopDefault(import('../pages/mb/mbc/SCR_MBC_0115_02.vue' /* webpackChunkName: "pages/mb/mbc/SCR_MBC_0115_02" */))
const _284e06ef = () => interopDefault(import('../pages/mb/mbc/SCR_MBC_0115_03.vue' /* webpackChunkName: "pages/mb/mbc/SCR_MBC_0115_03" */))
const _d45278f8 = () => interopDefault(import('../pages/mb/mbc/SCR_MBC_0116.vue' /* webpackChunkName: "pages/mb/mbc/SCR_MBC_0116" */))
const _10179caa = () => interopDefault(import('../pages/mb/mbc/SCR_MBC_0117_01.vue' /* webpackChunkName: "pages/mb/mbc/SCR_MBC_0117_01" */))
const _0ffb6da8 = () => interopDefault(import('../pages/mb/mbc/SCR_MBC_0117_02.vue' /* webpackChunkName: "pages/mb/mbc/SCR_MBC_0117_02" */))
const _d191e1c6 = () => interopDefault(import('../pages/mb/mbc/SCR_MBC_0120.vue' /* webpackChunkName: "pages/mb/mbc/SCR_MBC_0120" */))
const _54951a0f = () => interopDefault(import('../pages/mb/mbc/SCR_MBC_0124_01.vue' /* webpackChunkName: "pages/mb/mbc/SCR_MBC_0124_01" */))
const _54a33190 = () => interopDefault(import('../pages/mb/mbc/SCR_MBC_0124_02.vue' /* webpackChunkName: "pages/mb/mbc/SCR_MBC_0124_02" */))
const _54b14911 = () => interopDefault(import('../pages/mb/mbc/SCR_MBC_0124_03.vue' /* webpackChunkName: "pages/mb/mbc/SCR_MBC_0124_03" */))
const _207ba812 = () => interopDefault(import('../pages/ms/msb/SCR_MSB_0001.vue' /* webpackChunkName: "pages/ms/msb/SCR_MSB_0001" */))
const _2089bf93 = () => interopDefault(import('../pages/ms/msb/SCR_MSB_0002.vue' /* webpackChunkName: "pages/ms/msb/SCR_MSB_0002" */))
const _2097d714 = () => interopDefault(import('../pages/ms/msb/SCR_MSB_0003.vue' /* webpackChunkName: "pages/ms/msb/SCR_MSB_0003" */))
const _20a5ee95 = () => interopDefault(import('../pages/ms/msb/SCR_MSB_0004.vue' /* webpackChunkName: "pages/ms/msb/SCR_MSB_0004" */))
const _20ec641a = () => interopDefault(import('../pages/ms/msb/SCR_MSB_0009.vue' /* webpackChunkName: "pages/ms/msb/SCR_MSB_0009" */))
const _463a3665 = () => interopDefault(import('../pages/ms/msb/SCR_MSB_TEMP.vue' /* webpackChunkName: "pages/ms/msb/SCR_MSB_TEMP" */))
const _4d520960 = () => interopDefault(import('../pages/ms/msc/SCR_MSC_0001.vue' /* webpackChunkName: "pages/ms/msc/SCR_MSC_0001" */))
const _4d35da5e = () => interopDefault(import('../pages/ms/msc/SCR_MSC_0002.vue' /* webpackChunkName: "pages/ms/msc/SCR_MSC_0002" */))
const _4d19ab5c = () => interopDefault(import('../pages/ms/msc/SCR_MSC_0003.vue' /* webpackChunkName: "pages/ms/msc/SCR_MSC_0003" */))
const _4b0da1cc = () => interopDefault(import('../pages/ms/mse/SCR_MSE_0001.vue' /* webpackChunkName: "pages/ms/mse/SCR_MSE_0001" */))
const _4b1bb94d = () => interopDefault(import('../pages/ms/mse/SCR_MSE_0002.vue' /* webpackChunkName: "pages/ms/mse/SCR_MSE_0002" */))
const _829d022a = () => interopDefault(import('../pages/ms/msg/SCR_MSG_0015.vue' /* webpackChunkName: "pages/ms/msg/SCR_MSG_0015" */))
const _8280d328 = () => interopDefault(import('../pages/ms/msg/SCR_MSG_0016.vue' /* webpackChunkName: "pages/ms/msg/SCR_MSG_0016" */))
const _8264a426 = () => interopDefault(import('../pages/ms/msg/SCR_MSG_0017.vue' /* webpackChunkName: "pages/ms/msg/SCR_MSG_0017" */))
const _822c4622 = () => interopDefault(import('../pages/ms/msg/SCR_MSG_0019.vue' /* webpackChunkName: "pages/ms/msg/SCR_MSG_0019" */))
const _7fa40cf4 = () => interopDefault(import('../pages/ms/msg/SCR_MSG_0021.vue' /* webpackChunkName: "pages/ms/msg/SCR_MSG_0021" */))
const _1cd4a09e = () => interopDefault(import('../pages/or/ora/SCR_ORA_0001.vue' /* webpackChunkName: "pages/or/ora/SCR_ORA_0001" */))
const _1c63e496 = () => interopDefault(import('../pages/or/ora/SCR_ORA_0005.vue' /* webpackChunkName: "pages/or/ora/SCR_ORA_0005" */))
const _0ba14290 = () => interopDefault(import('../pages/or/ora/SCR_ORA_0005_S1.vue' /* webpackChunkName: "pages/or/ora/SCR_ORA_0005_S1" */))
const _1c47b594 = () => interopDefault(import('../pages/or/ora/SCR_ORA_0006.vue' /* webpackChunkName: "pages/or/ora/SCR_ORA_0006" */))
const _1c2b8692 = () => interopDefault(import('../pages/or/ora/SCR_ORA_0007.vue' /* webpackChunkName: "pages/or/ora/SCR_ORA_0007" */))
const _1bf3288e = () => interopDefault(import('../pages/or/ora/SCR_ORA_0009.vue' /* webpackChunkName: "pages/or/ora/SCR_ORA_0009" */))
const _5022d160 = () => interopDefault(import('../pages/or/ora/SCR_ORA_0009I.vue' /* webpackChunkName: "pages/or/ora/SCR_ORA_0009I" */))
const _508575e7 = () => interopDefault(import('../pages/or/ora/SCR_ORA_0009P.vue' /* webpackChunkName: "pages/or/ora/SCR_ORA_0009P" */))
const _19871e62 = () => interopDefault(import('../pages/or/ora/SCR_ORA_0010.vue' /* webpackChunkName: "pages/or/ora/SCR_ORA_0010" */))
const _196aef60 = () => interopDefault(import('../pages/or/ora/SCR_ORA_0011.vue' /* webpackChunkName: "pages/or/ora/SCR_ORA_0011" */))
const _1932915c = () => interopDefault(import('../pages/or/ora/SCR_ORA_0013.vue' /* webpackChunkName: "pages/or/ora/SCR_ORA_0013" */))
const _1916625a = () => interopDefault(import('../pages/or/ora/SCR_ORA_0014.vue' /* webpackChunkName: "pages/or/ora/SCR_ORA_0014" */))
const _47632078 = () => interopDefault(import('../pages/or/orb/cancelCompletePopUp.vue' /* webpackChunkName: "pages/or/orb/cancelCompletePopUp" */))
const _f248dc5c = () => interopDefault(import('../pages/or/orb/ExchangeList.vue' /* webpackChunkName: "pages/or/orb/ExchangeList" */))
const _a315c16c = () => interopDefault(import('../pages/or/orb/NoCancelList.vue' /* webpackChunkName: "pages/or/orb/NoCancelList" */))
const _ece7e11a = () => interopDefault(import('../pages/or/orb/NoExchangeList.vue' /* webpackChunkName: "pages/or/orb/NoExchangeList" */))
const _71cd3004 = () => interopDefault(import('../pages/or/orb/OrBtnCmp.vue' /* webpackChunkName: "pages/or/orb/OrBtnCmp" */))
const _1e1d69ac = () => interopDefault(import('../pages/or/orb/OrClAdditPyUnit.vue' /* webpackChunkName: "pages/or/orb/OrClAdditPyUnit" */))
const _80321e9a = () => interopDefault(import('../pages/or/orb/OrClDlvInfoUnit.vue' /* webpackChunkName: "pages/or/orb/OrClDlvInfoUnit" */))
const _00efc9d3 = () => interopDefault(import('../pages/or/orb/OrClPdListUnit.vue' /* webpackChunkName: "pages/or/orb/OrClPdListUnit" */))
const _ab1a2dc8 = () => interopDefault(import('../pages/or/orb/OrClRetnInfoUnit.vue' /* webpackChunkName: "pages/or/orb/OrClRetnInfoUnit" */))
const _aeb77d76 = () => interopDefault(import('../pages/or/orb/OrClRetnPyInfoUnit.vue' /* webpackChunkName: "pages/or/orb/OrClRetnPyInfoUnit" */))
const _ab1dfa22 = () => interopDefault(import('../pages/or/orb/SCR_ORB_0001.vue' /* webpackChunkName: "pages/or/orb/SCR_ORB_0001" */))
const _ab01cb20 = () => interopDefault(import('../pages/or/orb/SCR_ORB_0002.vue' /* webpackChunkName: "pages/or/orb/SCR_ORB_0002" */))
const _aae59c1e = () => interopDefault(import('../pages/or/orb/SCR_ORB_0003.vue' /* webpackChunkName: "pages/or/orb/SCR_ORB_0003" */))
const _aac96d1c = () => interopDefault(import('../pages/or/orb/SCR_ORB_0004.vue' /* webpackChunkName: "pages/or/orb/SCR_ORB_0004" */))
const _aaad3e1a = () => interopDefault(import('../pages/or/orb/SCR_ORB_0005.vue' /* webpackChunkName: "pages/or/orb/SCR_ORB_0005" */))
const _15694a8c = () => interopDefault(import('../pages/or/orb/SCR_ORB_0005_S1.vue' /* webpackChunkName: "pages/or/orb/SCR_ORB_0005_S1" */))
const _aa910f18 = () => interopDefault(import('../pages/or/orb/SCR_ORB_0006.vue' /* webpackChunkName: "pages/or/orb/SCR_ORB_0006" */))
const _aa74e016 = () => interopDefault(import('../pages/or/orb/SCR_ORB_0007.vue' /* webpackChunkName: "pages/or/orb/SCR_ORB_0007" */))
const _a7d077e6 = () => interopDefault(import('../pages/or/orb/SCR_ORB_0010.vue' /* webpackChunkName: "pages/or/orb/SCR_ORB_0010" */))
const _a77beae0 = () => interopDefault(import('../pages/or/orb/SCR_ORB_0013.vue' /* webpackChunkName: "pages/or/orb/SCR_ORB_0013" */))
const _a75fbbde = () => interopDefault(import('../pages/or/orb/SCR_ORB_0014.vue' /* webpackChunkName: "pages/or/orb/SCR_ORB_0014" */))
const _a7438cdc = () => interopDefault(import('../pages/or/orb/SCR_ORB_0015.vue' /* webpackChunkName: "pages/or/orb/SCR_ORB_0015" */))
const _a7275dda = () => interopDefault(import('../pages/or/orb/SCR_ORB_0016.vue' /* webpackChunkName: "pages/or/orb/SCR_ORB_0016" */))
const _a70b2ed8 = () => interopDefault(import('../pages/or/orb/SCR_ORB_0017.vue' /* webpackChunkName: "pages/or/orb/SCR_ORB_0017" */))
const _a6eeffd6 = () => interopDefault(import('../pages/or/orb/SCR_ORB_0018.vue' /* webpackChunkName: "pages/or/orb/SCR_ORB_0018" */))
const _a6d2d0d4 = () => interopDefault(import('../pages/or/orb/SCR_ORB_0019.vue' /* webpackChunkName: "pages/or/orb/SCR_ORB_0019" */))
const _a44a97a6 = () => interopDefault(import('../pages/or/orb/SCR_ORB_0021.vue' /* webpackChunkName: "pages/or/orb/SCR_ORB_0021" */))
const _a41239a2 = () => interopDefault(import('../pages/or/orb/SCR_ORB_0023.vue' /* webpackChunkName: "pages/or/orb/SCR_ORB_0023" */))
const _a3f60aa0 = () => interopDefault(import('../pages/or/orb/SCR_ORB_0024.vue' /* webpackChunkName: "pages/or/orb/SCR_ORB_0024" */))
const _a3d9db9e = () => interopDefault(import('../pages/or/orb/SCR_ORB_0025.vue' /* webpackChunkName: "pages/or/orb/SCR_ORB_0025" */))
const _a0a88864 = () => interopDefault(import('../pages/or/orb/SCR_ORB_0033.vue' /* webpackChunkName: "pages/or/orb/SCR_ORB_0033" */))
const _a053fb5e = () => interopDefault(import('../pages/or/orb/SCR_ORB_0036.vue' /* webpackChunkName: "pages/or/orb/SCR_ORB_0036" */))
const _a037cc5c = () => interopDefault(import('../pages/or/orb/SCR_ORB_0037.vue' /* webpackChunkName: "pages/or/orb/SCR_ORB_0037" */))
const _9a29b2ee = () => interopDefault(import('../pages/or/orb/SCR_ORB_0050.vue' /* webpackChunkName: "pages/or/orb/SCR_ORB_0050" */))
const _68261ab3 = () => interopDefault(import('../pages/or/orb/UX_07_055.vue' /* webpackChunkName: "pages/or/orb/UX_07_055" */))
const _d02467d2 = () => interopDefault(import('../pages/or/orb/UX_07_125.vue' /* webpackChunkName: "pages/or/orb/UX_07_125" */))
const _634c562d = () => interopDefault(import('../pages/or/orc/SCR_ORC_0001.vue' /* webpackChunkName: "pages/or/orc/SCR_ORC_0001" */))
const _52977bdf = () => interopDefault(import('../pages/or/orc/SCR_ORC_0001_T1.vue' /* webpackChunkName: "pages/or/orc/SCR_ORC_0001_T1" */))
const _52a59360 = () => interopDefault(import('../pages/or/orc/SCR_ORC_0001_T2.vue' /* webpackChunkName: "pages/or/orc/SCR_ORC_0001_T2" */))
const _52b3aae1 = () => interopDefault(import('../pages/or/orc/SCR_ORC_0001_T3.vue' /* webpackChunkName: "pages/or/orc/SCR_ORC_0001_T3" */))
const _52c1c262 = () => interopDefault(import('../pages/or/orc/SCR_ORC_0001_T4.vue' /* webpackChunkName: "pages/or/orc/SCR_ORC_0001_T4" */))
const _5d5f450e = () => interopDefault(import('../pages/or/orc/SCR_ORC_0001_T4_P1.vue' /* webpackChunkName: "pages/or/orc/SCR_ORC_0001_T4_P1" */))
const _5d6d5c8f = () => interopDefault(import('../pages/or/orc/SCR_ORC_0001_T4_P2.vue' /* webpackChunkName: "pages/or/orc/SCR_ORC_0001_T4_P2" */))
const _6368852f = () => interopDefault(import('../pages/or/orc/SCR_ORC_0003.vue' /* webpackChunkName: "pages/or/orc/SCR_ORC_0003" */))
const _63a0e333 = () => interopDefault(import('../pages/or/orc/SCR_ORC_0007.vue' /* webpackChunkName: "pages/or/orc/SCR_ORC_0007" */))
const _164d6f4b = () => interopDefault(import('../pages/pd/pdm/SCR_PDM_0002.vue' /* webpackChunkName: "pages/pd/pdm/SCR_PDM_0002" */))
const _9af01000 = () => interopDefault(import('../pages/pd/pdr/SCR_PDR_0001.vue' /* webpackChunkName: "pages/pd/pdr/SCR_PDR_0001" */))
const _6b5533bd = () => interopDefault(import('../pages/pd/pds/SCR_PDS_0000.vue' /* webpackChunkName: "pages/pd/pds/SCR_PDS_0000" */))
const _6b9ba942 = () => interopDefault(import('../pages/pd/pds/SCR_PDS_0005.vue' /* webpackChunkName: "pages/pd/pds/SCR_PDS_0005" */))
const _6ba9c0c3 = () => interopDefault(import('../pages/pd/pds/SCR_PDS_0006.vue' /* webpackChunkName: "pages/pd/pds/SCR_PDS_0006" */))
const _243e9e7c = () => interopDefault(import('../pages/pd/pdt/SCR_PDT_0001.vue' /* webpackChunkName: "pages/pd/pdt/SCR_PDT_0001" */))
const _244cb5fd = () => interopDefault(import('../pages/pd/pdt/SCR_PDT_0002.vue' /* webpackChunkName: "pages/pd/pdt/SCR_PDT_0002" */))
const _245acd7e = () => interopDefault(import('../pages/pd/pdt/SCR_PDT_0003.vue' /* webpackChunkName: "pages/pd/pdt/SCR_PDT_0003" */))
const _2468e4ff = () => interopDefault(import('../pages/pd/pdt/SCR_PDT_0004.vue' /* webpackChunkName: "pages/pd/pdt/SCR_PDT_0004" */))
const _ea8af3d0 = () => interopDefault(import('../pages/py/pya/SCR_PYA_0002.vue' /* webpackChunkName: "pages/py/pya/SCR_PYA_0002" */))
const _ea6ec4ce = () => interopDefault(import('../pages/py/pya/SCR_PYA_0003.vue' /* webpackChunkName: "pages/py/pya/SCR_PYA_0003" */))
const _ea5295cc = () => interopDefault(import('../pages/py/pya/SCR_PYA_0004.vue' /* webpackChunkName: "pages/py/pya/SCR_PYA_0004" */))
const _ea3666ca = () => interopDefault(import('../pages/py/pya/SCR_PYA_0005.vue' /* webpackChunkName: "pages/py/pya/SCR_PYA_0005" */))
const _e9fe08c6 = () => interopDefault(import('../pages/py/pya/SCR_PYA_0007.vue' /* webpackChunkName: "pages/py/pya/SCR_PYA_0007" */))
const _2bec72bc = () => interopDefault(import('../pages/ug/ugc/SCR_UGC_030.vue' /* webpackChunkName: "pages/ug/ugc/SCR_UGC_030" */))
const _718b877d = () => interopDefault(import('../pages/ug/ugd/SCR_UGD_0004.vue' /* webpackChunkName: "pages/ug/ugd/SCR_UGD_0004" */))
const _71b5ce00 = () => interopDefault(import('../pages/ug/ugd/SCR_UGD_0007.vue' /* webpackChunkName: "pages/ug/ugd/SCR_UGD_0007" */))
const _71c3e581 = () => interopDefault(import('../pages/ug/ugd/SCR_UGD_0008.vue' /* webpackChunkName: "pages/ug/ugd/SCR_UGD_0008" */))
const _7340601c = () => interopDefault(import('../pages/ug/ugd/SCR_UGD_0014.vue' /* webpackChunkName: "pages/ug/ugd/SCR_UGD_0014" */))
const _7386d5a1 = () => interopDefault(import('../pages/ug/ugd/SCR_UGD_0019.vue' /* webpackChunkName: "pages/ug/ugd/SCR_UGD_0019" */))
const _74d909b9 = () => interopDefault(import('../pages/ug/ugd/SCR_UGD_0022.vue' /* webpackChunkName: "pages/ug/ugd/SCR_UGD_0022" */))
const _74e7213a = () => interopDefault(import('../pages/ug/ugd/SCR_UGD_0023.vue' /* webpackChunkName: "pages/ug/ugd/SCR_UGD_0023" */))
const _752d96bf = () => interopDefault(import('../pages/ug/ugd/SCR_UGD_0028.vue' /* webpackChunkName: "pages/ug/ugd/SCR_UGD_0028" */))
const _309cea83 = () => interopDefault(import('../pages/ug/ugd/SCR_UGD_00282.vue' /* webpackChunkName: "pages/ug/ugd/SCR_UGD_00282" */))
const _753bae40 = () => interopDefault(import('../pages/ug/ugd/SCR_UGD_0029.vue' /* webpackChunkName: "pages/ug/ugd/SCR_UGD_0029" */))
const _7671b356 = () => interopDefault(import('../pages/ug/ugd/SCR_UGD_0030.vue' /* webpackChunkName: "pages/ug/ugd/SCR_UGD_0030" */))
const _767fcad7 = () => interopDefault(import('../pages/ug/ugd/SCR_UGD_0031.vue' /* webpackChunkName: "pages/ug/ugd/SCR_UGD_0031" */))
const _768de258 = () => interopDefault(import('../pages/ug/ugd/SCR_UGD_0032.vue' /* webpackChunkName: "pages/ug/ugd/SCR_UGD_0032" */))
const _769bf9d9 = () => interopDefault(import('../pages/ug/ugd/SCR_UGD_0033.vue' /* webpackChunkName: "pages/ug/ugd/SCR_UGD_0033" */))
const _e7087392 = () => interopDefault(import('../pages/ug/ugd/SCR_UGD_0033_P1.vue' /* webpackChunkName: "pages/ug/ugd/SCR_UGD_0033_P1" */))
const _2ad126d8 = () => interopDefault(import('../pages/ug/ugd/SCR_UGD_00up.vue' /* webpackChunkName: "pages/ug/ugd/SCR_UGD_00up" */))
const _383bdaea = () => interopDefault(import('../pages/ug/ugl/SCR_UGL_0001.vue' /* webpackChunkName: "pages/ug/ugl/SCR_UGL_0001" */))
const _1dd1a3b0 = () => interopDefault(import('../pages/ug/ugm/SCR_UGM_0001.vue' /* webpackChunkName: "pages/ug/ugm/SCR_UGM_0001" */))
const _1ba927e2 = () => interopDefault(import('../pages/ug/ugp/SCR_UGP_0001.vue' /* webpackChunkName: "pages/ug/ugp/SCR_UGP_0001" */))
const _1bb73f63 = () => interopDefault(import('../pages/ug/ugp/SCR_UGP_0002.vue' /* webpackChunkName: "pages/ug/ugp/SCR_UGP_0002" */))
const _1bc556e4 = () => interopDefault(import('../pages/ug/ugp/SCR_UGP_0003.vue' /* webpackChunkName: "pages/ug/ugp/SCR_UGP_0003" */))
const _d6036d68 = () => interopDefault(import('../pages/ug/ugp/SCR_UGP_0003_P1.vue' /* webpackChunkName: "pages/ug/ugp/SCR_UGP_0003_P1" */))
const _5ce0fffc = () => interopDefault(import('../pages/ug/ugp/SCR_UGP_00030.vue' /* webpackChunkName: "pages/ug/ugp/SCR_UGP_00030" */))
const _1bd36e65 = () => interopDefault(import('../pages/ug/ugp/SCR_UGP_0004.vue' /* webpackChunkName: "pages/ug/ugp/SCR_UGP_0004" */))
const _1be185e6 = () => interopDefault(import('../pages/ug/ugp/SCR_UGP_0005.vue' /* webpackChunkName: "pages/ug/ugp/SCR_UGP_0005" */))
const _1bfdb4e8 = () => interopDefault(import('../pages/ug/ugp/SCR_UGP_0007.vue' /* webpackChunkName: "pages/ug/ugp/SCR_UGP_0007" */))
const _3482fcc8 = () => interopDefault(import('../pages/ug/ugp/SCR_UGP_0007_P1.vue' /* webpackChunkName: "pages/ug/ugp/SCR_UGP_0007_P1" */))
const _1c19e3ea = () => interopDefault(import('../pages/ug/ugp/SCR_UGP_0009.vue' /* webpackChunkName: "pages/ug/ugp/SCR_UGP_0009" */))
const _1d4fe900 = () => interopDefault(import('../pages/ug/ugp/SCR_UGP_0010.vue' /* webpackChunkName: "pages/ug/ugp/SCR_UGP_0010" */))
const _1cc19ca0 = () => interopDefault(import('../pages/ug/ugp/SCR_UGP_0010_P1.vue' /* webpackChunkName: "pages/ug/ugp/SCR_UGP_0010_P1" */))
const _1d6c1802 = () => interopDefault(import('../pages/ug/ugp/SCR_UGP_0012.vue' /* webpackChunkName: "pages/ug/ugp/SCR_UGP_0012" */))
const _1d7a2f83 = () => interopDefault(import('../pages/ug/ugp/SCR_UGP_0013.vue' /* webpackChunkName: "pages/ug/ugp/SCR_UGP_0013" */))
const _1d884704 = () => interopDefault(import('../pages/ug/ugp/SCR_UGP_0014.vue' /* webpackChunkName: "pages/ug/ugp/SCR_UGP_0014" */))
const _1db28d87 = () => interopDefault(import('../pages/ug/ugp/SCR_UGP_0017.vue' /* webpackChunkName: "pages/ug/ugp/SCR_UGP_0017" */))
const _1dc0a508 = () => interopDefault(import('../pages/ug/ugp/SCR_UGP_0018.vue' /* webpackChunkName: "pages/ug/ugp/SCR_UGP_0018" */))
const _1dcebc89 = () => interopDefault(import('../pages/ug/ugp/SCR_UGP_0019.vue' /* webpackChunkName: "pages/ug/ugp/SCR_UGP_0019" */))
const _ceec74f2 = () => interopDefault(import('../pages/ug/ugp/SCR_UGP_0019_P1.vue' /* webpackChunkName: "pages/ug/ugp/SCR_UGP_0019_P1" */))
const _1f04c19f = () => interopDefault(import('../pages/ug/ugp/SCR_UGP_0020.vue' /* webpackChunkName: "pages/ug/ugp/SCR_UGP_0020" */))
const _7fc9065a = () => interopDefault(import('../pages/ds/diy/_id.vue' /* webpackChunkName: "pages/ds/diy/_id" */))
const _2551468b = () => interopDefault(import('../pages/ds/dst/_id.vue' /* webpackChunkName: "pages/ds/dst/_id" */))
const _1b1414a1 = () => interopDefault(import('../pages/ds/dsTest/_id.vue' /* webpackChunkName: "pages/ds/dsTest/_id" */))
const _831b3336 = () => interopDefault(import('../pages/ds/exbt/_id.vue' /* webpackChunkName: "pages/ds/exbt/_id" */))
const _262e671c = () => interopDefault(import('../pages/ds/fb/_id.vue' /* webpackChunkName: "pages/ds/fb/_id" */))
const _82d6e02a = () => interopDefault(import('../pages/ds/godbin/_id.vue' /* webpackChunkName: "pages/ds/godbin/_id" */))
const _56023b48 = () => interopDefault(import('../pages/ds/goods/_id.vue' /* webpackChunkName: "pages/ds/goods/_id" */))
const _e4d5bc6c = () => interopDefault(import('../pages/ds/goodssub/_id.vue' /* webpackChunkName: "pages/ds/goodssub/_id" */))
const _57095f66 = () => interopDefault(import('../pages/ds/new/_id.vue' /* webpackChunkName: "pages/ds/new/_id" */))
const _fb5a1a72 = () => interopDefault(import('../pages/ds/prodGift/_id.vue' /* webpackChunkName: "pages/ds/prodGift/_id" */))
const _0215b968 = () => interopDefault(import('../pages/ds/rank/_id.vue' /* webpackChunkName: "pages/ds/rank/_id" */))
const _98ec67a6 = () => interopDefault(import('../pages/ds/recmd/_id.vue' /* webpackChunkName: "pages/ds/recmd/_id" */))
const _0ab2706a = () => interopDefault(import('../pages/ds/recmd2/_id.vue' /* webpackChunkName: "pages/ds/recmd2/_id" */))
const _21d7ccde = () => interopDefault(import('../pages/ds/specialist/_id.vue' /* webpackChunkName: "pages/ds/specialist/_id" */))
const _3a1945e3 = () => interopDefault(import('../pages/ds/specialProds/_id.vue' /* webpackChunkName: "pages/ds/specialProds/_id" */))
const _46ca0164 = () => interopDefault(import('../pages/ds/dsBrand/_id/_brandId.vue' /* webpackChunkName: "pages/ds/dsBrand/_id/_brandId" */))
const _180d5980 = () => interopDefault(import('../pages/ds/spexhbt/_id/_exbtId.vue' /* webpackChunkName: "pages/ds/spexhbt/_id/_exbtId" */))
const _332373ee = () => interopDefault(import('../pages/ds/exhCtgr/_cnrId/_lgCtgrNo/_mdCtgrNo/_smCtgrNo.vue' /* webpackChunkName: "pages/ds/exhCtgr/_cnrId/_lgCtgrNo/_mdCtgrNo/_smCtgrNo" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/ds",
    component: _279d08ad,
    name: "ds"
  }, {
    path: "/ds/biz",
    component: _2a28092e,
    name: "ds-biz"
  }, {
    path: "/ds/intro",
    component: _e89e2d72,
    name: "ds-intro"
  }, {
    path: "/ds/member",
    component: _522b424f,
    name: "ds-member"
  }, {
    path: "/bp/bpa/SCR_BPA_0001",
    component: _33aa007c,
    name: "bp-bpa-SCR_BPA_0001"
  }, {
    path: "/bp/bpa/SCR_BPA_0001A",
    component: _428118f5,
    name: "bp-bpa-SCR_BPA_0001A"
  }, {
    path: "/bp/bpa/SCR_BPA_0008",
    component: _340ca503,
    name: "bp-bpa-SCR_BPA_0008"
  }, {
    path: "/cm/cma/SCR_CMA_0001",
    component: _9c1eafd0,
    name: "cm-cma-SCR_CMA_0001"
  }, {
    path: "/cs/csh/SCR_CSH_0000",
    component: _365eb20f,
    name: "cs-csh-SCR_CSH_0000"
  }, {
    path: "/cs/csh/SCR_CSH_0001",
    component: _366cc990,
    name: "cs-csh-SCR_CSH_0001"
  }, {
    path: "/cs/csh/SCR_CSH_0002",
    component: _367ae111,
    name: "cs-csh-SCR_CSH_0002"
  }, {
    path: "/cs/csh/SCR_CSH_0003",
    component: _3688f892,
    name: "cs-csh-SCR_CSH_0003"
  }, {
    path: "/cs/csh/SCR_CSH_0004",
    component: _36971013,
    name: "cs-csh-SCR_CSH_0004"
  }, {
    path: "/cs/csi/SCR_CSI_0001",
    component: _216fc664,
    name: "cs-csi-SCR_CSI_0001"
  }, {
    path: "/cs/csk/SCR_CSK_0000",
    component: _60f0abc9,
    name: "cs-csk-SCR_CSK_0000"
  }, {
    path: "/cs/csk/SCR_CSK_0001",
    component: _60fec34a,
    name: "cs-csk-SCR_CSK_0001"
  }, {
    path: "/cs/csk/SCR_CSK_0002",
    component: _610cdacb,
    name: "cs-csk-SCR_CSK_0002"
  }, {
    path: "/cs/csl/SCR_CSL_0001",
    component: _19da1688,
    name: "cs-csl-SCR_CSL_0001"
  }, {
    path: "/cs/csl/SCR_CSL_0001_P1",
    component: _4587b728,
    name: "cs-csl-SCR_CSL_0001_P1"
  }, {
    path: "/cs/csl/SCR_CSL_0001_P2",
    component: _4595cea9,
    name: "cs-csl-SCR_CSL_0001_P2"
  }, {
    path: "/cs/csl/SCR_CSL_0001_P3",
    component: _45a3e62a,
    name: "cs-csl-SCR_CSL_0001_P3"
  }, {
    path: "/cs/csl/SCR_CSL_0004",
    component: _1a045d0b,
    name: "cs-csl-SCR_CSL_0004"
  }, {
    path: "/cs/csl/SCR_CSL_0004_I",
    component: _0936b9d6,
    name: "cs-csl-SCR_CSL_0004_I"
  }, {
    path: "/cs/csl/SCR_CSL_0004_P",
    component: _087170c8,
    name: "cs-csl-SCR_CSL_0004_P"
  }, {
    path: "/cs/csm/SCR_CSM_0000",
    component: _5ab15b76,
    name: "cs-csm-SCR_CSM_0000"
  }, {
    path: "/cs/csm/SCR_CSM_0001",
    component: _5a952c74,
    name: "cs-csm-SCR_CSM_0001"
  }, {
    path: "/cs/csm/SCR_CSM_0002",
    component: _5a78fd72,
    name: "cs-csm-SCR_CSM_0002"
  }, {
    path: "/cs/csn/SCR_CSN_0001_P",
    component: _3bbb8d55,
    name: "cs-csn-SCR_CSN_0001_P"
  }, {
    path: "/ds/dsa/chatbot",
    component: _21ea3dad,
    name: "ds-dsa-chatbot"
  }, {
    path: "/ds/dsa/SCR_MBC_0050_02_1",
    component: _719b5730,
    name: "ds-dsa-SCR_MBC_0050_02_1"
  }, {
    path: "/ds/dsa/SCR_MBC_0113",
    component: _3968a2fa,
    name: "ds-dsa-SCR_MBC_0113"
  }, {
    path: "/ds/dsa/SCR_MBC_0113_01",
    component: _48f1c56d,
    name: "ds-dsa-SCR_MBC_0113_01"
  }, {
    path: "/ds/dsa/SCR_MBC_0113_02",
    component: _48ffdcee,
    name: "ds-dsa-SCR_MBC_0113_02"
  }, {
    path: "/ds/dsa/SCR_MBC_0113_03",
    component: _490df46f,
    name: "ds-dsa-SCR_MBC_0113_03"
  }, {
    path: "/ds/dsa/SCR_MBC_0113_04",
    component: _491c0bf0,
    name: "ds-dsa-SCR_MBC_0113_04"
  }, {
    path: "/ds/dsa/SCR_MBC_0113_05",
    component: _492a2371,
    name: "ds-dsa-SCR_MBC_0113_05"
  }, {
    path: "/ds/dsa/videoTest",
    component: _68dbc96b,
    name: "ds-dsa-videoTest"
  }, {
    path: "/ds/dst/SCR_DST_0015",
    component: _1c26fc1a,
    name: "ds-dst-SCR_DST_0015"
  }, {
    path: "/ds/keybiz/keyVisualList",
    component: _38b0b837,
    name: "ds-keybiz-keyVisualList"
  }, {
    path: "/ds/keybiz/pdNotification",
    component: _5d861816,
    name: "ds-keybiz-pdNotification"
  }, {
    path: "/ds/rank/popular",
    component: _c9cb202a,
    name: "ds-rank-popular"
  }, {
    path: "/ds/rank/rePurchase",
    component: _5d2b03b2,
    name: "ds-rank-rePurchase"
  }, {
    path: "/ds/rank/review",
    component: _3980d094,
    name: "ds-rank-review"
  }, {
    path: "/ev/evc/SCR_EVC_0001",
    component: _6f267e8a,
    name: "ev-evc-SCR_EVC_0001"
  }, {
    path: "/ev/evc/SCR_EVC_0003",
    component: _6eee2086,
    name: "ev-evc-SCR_EVC_0003"
  }, {
    path: "/ev/evc/SCR_EVC_0009",
    component: _6e45067a,
    name: "ev-evc-SCR_EVC_0009"
  }, {
    path: "/ev/evc/SCR_EVC_0011",
    component: _6bbccd4c,
    name: "ev-evc-SCR_EVC_0011"
  }, {
    path: "/ev/evc/SCR_EVC_0013",
    component: _6b846f48,
    name: "ev-evc-SCR_EVC_0013"
  }, {
    path: "/ev/evc/SCR_EVC_0015",
    component: _6b4c1144,
    name: "ev-evc-SCR_EVC_0015"
  }, {
    path: "/ev/evc/SCR_EVC_0017",
    component: _6b13b340,
    name: "ev-evc-SCR_EVC_0017"
  }, {
    path: "/ev/evc/SCR_EVC_0019",
    component: _6adb553c,
    name: "ev-evc-SCR_EVC_0019"
  }, {
    path: "/ev/evd/SCR_EVD_0001",
    component: _fd6fd80e,
    name: "ev-evd-SCR_EVD_0001"
  }, {
    path: "/ev/evd/SCR_EVD_0003",
    component: _fd377a0a,
    name: "ev-evd-SCR_EVD_0003"
  }, {
    path: "/ev/evd/SCR_EVD_0008",
    component: _fcaa8f00,
    name: "ev-evd-SCR_EVD_0008"
  }, {
    path: "/ev/evd/SCR_EVD_0009",
    component: _fc8e5ffe,
    name: "ev-evd-SCR_EVD_0009"
  }, {
    path: "/ev/evd/SCR_EVD_0011",
    component: _fa0626d0,
    name: "ev-evd-SCR_EVD_0011"
  }, {
    path: "/fb/fba/SCR_FBA_0001",
    component: _5a9b00aa,
    name: "fb-fba-SCR_FBA_0001"
  }, {
    path: "/fb/fba/SCR_FBA_0003",
    component: _5ab72fac,
    name: "fb-fba-SCR_FBA_0003"
  }, {
    path: "/fb/fba/SCR_FBA_0005",
    component: _5ad35eae,
    name: "fb-fba-SCR_FBA_0005"
  }, {
    path: "/fb/fba/SCR_FBA_0005_S1",
    component: _5bd93f1f,
    name: "fb-fba-SCR_FBA_0005_S1"
  }, {
    path: "/fb/fba/SCR_FBA_0006",
    component: _5ae1762f,
    name: "fb-fba-SCR_FBA_0006"
  }, {
    path: "/fb/fbb/SCR_FBB_0001",
    component: _137653e8,
    name: "fb-fbb-SCR_FBB_0001"
  }, {
    path: "/fb/fbb/SCR_FBB_0001T1",
    component: _4c1cd3c5,
    name: "fb-fbb-SCR_FBB_0001T1"
  }, {
    path: "/fb/fbb/SCR_FBB_0001T1B",
    component: _3876c64d,
    name: "fb-fbb-SCR_FBB_0001T1B"
  }, {
    path: "/fb/fbb/SCR_FBB_0001T2",
    component: _4c2aeb46,
    name: "fb-fbb-SCR_FBB_0001T2"
  }, {
    path: "/fb/fbb/SCR_FBB_0002",
    component: _13846b69,
    name: "fb-fbb-SCR_FBB_0002"
  }, {
    path: "/fb/fbb/SCR_FBB_0003",
    component: _139282ea,
    name: "fb-fbb-SCR_FBB_0003"
  }, {
    path: "/fb/fbb/SCR_FBB_0005",
    component: _13aeb1ec,
    name: "fb-fbb-SCR_FBB_0005"
  }, {
    path: "/fb/fbb/SCR_FBB_0008",
    component: _13d8f86f,
    name: "fb-fbb-SCR_FBB_0008"
  }, {
    path: "/fb/fbb/SCR_FBB_0010",
    component: _151d1506,
    name: "fb-fbb-SCR_FBB_0010"
  }, {
    path: "/fb/fbb/SCR_FBB_0014",
    component: _1555730a,
    name: "fb-fbb-SCR_FBB_0014"
  }, {
    path: "/fb/fbb/SCR_FBB_0015",
    component: _15638a8b,
    name: "fb-fbb-SCR_FBB_0015"
  }, {
    path: "/mb/mba/SCR_MBA_0080",
    component: _0a517dcc,
    name: "mb-mba-SCR_MBA_0080"
  }, {
    path: "/mb/mba/SCR_MBA_0082_01",
    component: _13ebf134,
    name: "mb-mba-SCR_MBA_0082_01"
  }, {
    path: "/mb/mba/SCR_MBA_0082_02",
    component: _13fa08b5,
    name: "mb-mba-SCR_MBA_0082_02"
  }, {
    path: "/mb/mba/SCR_MBA_0082_03",
    component: _14082036,
    name: "mb-mba-SCR_MBA_0082_03"
  }, {
    path: "/mb/mba/SCR_MBA_0082_04",
    component: _141637b7,
    name: "mb-mba-SCR_MBA_0082_04"
  }, {
    path: "/mb/mba/SCR_MBA_0082_05",
    component: _14244f38,
    name: "mb-mba-SCR_MBA_0082_05"
  }, {
    path: "/mb/mba/SCR_MBA_0082_06",
    component: _143266b9,
    name: "mb-mba-SCR_MBA_0082_06"
  }, {
    path: "/mb/mba/SCR_MBA_0082_07",
    component: _14407e3a,
    name: "mb-mba-SCR_MBA_0082_07"
  }, {
    path: "/mb/mba/SCR_MBA_0082_09",
    component: _145cad3c,
    name: "mb-mba-SCR_MBA_0082_09"
  }, {
    path: "/mb/mba/SCR_MBA_0082_10",
    component: _1592b252,
    name: "mb-mba-SCR_MBA_0082_10"
  }, {
    path: "/mb/mba/SCR_MBA_0084",
    component: _09e0c1c4,
    name: "mb-mba-SCR_MBA_0084"
  }, {
    path: "/mb/mba/SCR_MBA_0086_01",
    component: _991eb6a0,
    name: "mb-mba-SCR_MBA_0086_01"
  }, {
    path: "/mb/mba/SCR_MBA_0086A",
    component: _6b38ffd1,
    name: "mb-mba-SCR_MBA_0086A"
  }, {
    path: "/mb/mba/SCR_MBA_0086B_01",
    component: _aa891384,
    name: "mb-mba-SCR_MBA_0086B_01"
  }, {
    path: "/mb/mba/SCR_MBA_0086B_02",
    component: _aa6ce482,
    name: "mb-mba-SCR_MBA_0086B_02"
  }, {
    path: "/mb/mba/SCR_MBA_0086C",
    component: _6b552ed3,
    name: "mb-mba-SCR_MBA_0086C"
  }, {
    path: "/mb/mba/SCR_MBA_0091",
    component: _06cb9d8c,
    name: "mb-mba-SCR_MBA_0091"
  }, {
    path: "/mb/mba/SCR_MBA_0093_01",
    component: _10118d14,
    name: "mb-mba-SCR_MBA_0093_01"
  }, {
    path: "/mb/mba/SCR_MBA_0093A_01",
    component: _7a566d7b,
    name: "mb-mba-SCR_MBA_0093A_01"
  }, {
    path: "/mb/mba/SCR_MBA_0093A_02",
    component: _7a6484fc,
    name: "mb-mba-SCR_MBA_0093A_02"
  }, {
    path: "/mb/mba/SCR_MBA_0093B",
    component: _c9e26e94,
    name: "mb-mba-SCR_MBA_0093B"
  }, {
    path: "/mb/mba/SCR_MBA_0093C_01",
    component: _4a18c739,
    name: "mb-mba-SCR_MBA_0093C_01"
  }, {
    path: "/mb/mba/SCR_MBA_0093C_02",
    component: _4a26deba,
    name: "mb-mba-SCR_MBA_0093C_02"
  }, {
    path: "/mb/mba/SCR_MBA_0094A",
    component: _c694ec58,
    name: "mb-mba-SCR_MBA_0094A"
  }, {
    path: "/mb/mba/SCR_MBA_0096_01",
    component: _47b513b1,
    name: "mb-mba-SCR_MBA_0096_01"
  }, {
    path: "/mb/mba/SCR_MBA_0096_02",
    component: _47c32b32,
    name: "mb-mba-SCR_MBA_0096_02"
  }, {
    path: "/mb/mba/SCR_MBA_0099_01",
    component: _7f589a4e,
    name: "mb-mba-SCR_MBA_0099_01"
  }, {
    path: "/mb/mbb/SCR_MBB_0097_01",
    component: _aa9b86dc,
    name: "mb-mbb-SCR_MBB_0097_01"
  }, {
    path: "/mb/mbb/SCR_MBB_0097A",
    component: _04632833,
    name: "mb-mbb-SCR_MBB_0097A"
  }, {
    path: "/mb/mbb/SCR_MBB_0097B",
    component: _04713fb4,
    name: "mb-mbb-SCR_MBB_0097B"
  }, {
    path: "/mb/mbb/SCR_MBB_0098",
    component: _944fae02,
    name: "mb-mbb-SCR_MBB_0098"
  }, {
    path: "/mb/mbb/SCR_MBB_0099",
    component: _94337f00,
    name: "mb-mbb-SCR_MBB_0099"
  }, {
    path: "/mb/mbb/SCR_MBB_0107_02",
    component: _68a1de29,
    name: "mb-mbb-SCR_MBB_0107_02"
  }, {
    path: "/mb/mbb/SCR_MBB_0107A_01",
    component: _321b6667,
    name: "mb-mbb-SCR_MBB_0107A_01"
  }, {
    path: "/mb/mbb/SCR_MBB_0107B_01",
    component: _cc06d974,
    name: "mb-mbb-SCR_MBB_0107B_01"
  }, {
    path: "/mb/mbb/SCR_MBB_0109A_01",
    component: _5aa44469,
    name: "mb-mbb-SCR_MBB_0109A_01"
  }, {
    path: "/mb/mbb/SCR_MBB_0109A_02",
    component: _5ab25bea,
    name: "mb-mbb-SCR_MBB_0109A_02"
  }, {
    path: "/mb/mbb/SCR_MBB_0109A_03",
    component: _5ac0736b,
    name: "mb-mbb-SCR_MBB_0109A_03"
  }, {
    path: "/mb/mbb/SCR_MBB_0109B_01",
    component: _7af51d70,
    name: "mb-mbb-SCR_MBB_0109B_01"
  }, {
    path: "/mb/mbb/SCR_MBB_0109B_02",
    component: _7ad8ee6e,
    name: "mb-mbb-SCR_MBB_0109B_02"
  }, {
    path: "/mb/mbb/SCR_MBB_0109B_03",
    component: _7abcbf6c,
    name: "mb-mbb-SCR_MBB_0109B_03"
  }, {
    path: "/mb/mbc/SCR_MBC_0110",
    component: _d4fb9304,
    name: "mb-mbc-SCR_MBC_0110"
  }, {
    path: "/mb/mbc/SCR_MBC_0110A",
    component: _1bafa333,
    name: "mb-mbc-SCR_MBC_0110A"
  }, {
    path: "/mb/mbc/SCR_MBC_0110A_01",
    component: _fe2d5a86,
    name: "mb-mbc-SCR_MBC_0110A_01"
  }, {
    path: "/mb/mbc/SCR_MBC_0110A_02",
    component: _fe112b84,
    name: "mb-mbc-SCR_MBC_0110A_02"
  }, {
    path: "/mb/mbc/SCR_MBC_0110B_01",
    component: _2e6b00c8,
    name: "mb-mbc-SCR_MBC_0110B_01"
  }, {
    path: "/mb/mbc/SCR_MBC_0110B_02",
    component: _2e4ed1c6,
    name: "mb-mbc-SCR_MBC_0110B_02"
  }, {
    path: "/mb/mbc/SCR_MBC_0111_01",
    component: _eea5b71e,
    name: "mb-mbc-SCR_MBC_0111_01"
  }, {
    path: "/mb/mbc/SCR_MBC_0111A",
    component: _1d647bd2,
    name: "mb-mbc-SCR_MBC_0111A"
  }, {
    path: "/mb/mbc/SCR_MBC_0111B",
    component: _1d729353,
    name: "mb-mbc-SCR_MBC_0111B"
  }, {
    path: "/mb/mbc/SCR_MBC_0111D_01",
    component: _4cd1485b,
    name: "mb-mbc-SCR_MBC_0111D_01"
  }, {
    path: "/mb/mbc/SCR_MBC_0111D_02",
    component: _4cdf5fdc,
    name: "mb-mbc-SCR_MBC_0111D_02"
  }, {
    path: "/mb/mbc/SCR_MBC_0111D_03",
    component: _4ced775d,
    name: "mb-mbc-SCR_MBC_0111D_03"
  }, {
    path: "/mb/mbc/SCR_MBC_0112",
    component: _d4c33500,
    name: "mb-mbc-SCR_MBC_0112"
  }, {
    path: "/mb/mbc/SCR_MBC_0113",
    component: _d4a705fe,
    name: "mb-mbc-SCR_MBC_0113"
  }, {
    path: "/mb/mbc/SCR_MBC_0114_01",
    component: _7f5ea9e4,
    name: "mb-mbc-SCR_MBC_0114_01"
  }, {
    path: "/mb/mbc/SCR_MBC_0114_02",
    component: _7f427ae2,
    name: "mb-mbc-SCR_MBC_0114_02"
  }, {
    path: "/mb/mbc/SCR_MBC_0115",
    component: _d46ea7fa,
    name: "mb-mbc-SCR_MBC_0115"
  }, {
    path: "/mb/mbc/SCR_MBC_0115_01",
    component: _2831d7ed,
    name: "mb-mbc-SCR_MBC_0115_01"
  }, {
    path: "/mb/mbc/SCR_MBC_0115_02",
    component: _283fef6e,
    name: "mb-mbc-SCR_MBC_0115_02"
  }, {
    path: "/mb/mbc/SCR_MBC_0115_03",
    component: _284e06ef,
    name: "mb-mbc-SCR_MBC_0115_03"
  }, {
    path: "/mb/mbc/SCR_MBC_0116",
    component: _d45278f8,
    name: "mb-mbc-SCR_MBC_0116"
  }, {
    path: "/mb/mbc/SCR_MBC_0117_01",
    component: _10179caa,
    name: "mb-mbc-SCR_MBC_0117_01"
  }, {
    path: "/mb/mbc/SCR_MBC_0117_02",
    component: _0ffb6da8,
    name: "mb-mbc-SCR_MBC_0117_02"
  }, {
    path: "/mb/mbc/SCR_MBC_0120",
    component: _d191e1c6,
    name: "mb-mbc-SCR_MBC_0120"
  }, {
    path: "/mb/mbc/SCR_MBC_0124_01",
    component: _54951a0f,
    name: "mb-mbc-SCR_MBC_0124_01"
  }, {
    path: "/mb/mbc/SCR_MBC_0124_02",
    component: _54a33190,
    name: "mb-mbc-SCR_MBC_0124_02"
  }, {
    path: "/mb/mbc/SCR_MBC_0124_03",
    component: _54b14911,
    name: "mb-mbc-SCR_MBC_0124_03"
  }, {
    path: "/ms/msb/SCR_MSB_0001",
    component: _207ba812,
    name: "ms-msb-SCR_MSB_0001"
  }, {
    path: "/ms/msb/SCR_MSB_0002",
    component: _2089bf93,
    name: "ms-msb-SCR_MSB_0002"
  }, {
    path: "/ms/msb/SCR_MSB_0003",
    component: _2097d714,
    name: "ms-msb-SCR_MSB_0003"
  }, {
    path: "/ms/msb/SCR_MSB_0004",
    component: _20a5ee95,
    name: "ms-msb-SCR_MSB_0004"
  }, {
    path: "/ms/msb/SCR_MSB_0009",
    component: _20ec641a,
    name: "ms-msb-SCR_MSB_0009"
  }, {
    path: "/ms/msb/SCR_MSB_TEMP",
    component: _463a3665,
    name: "ms-msb-SCR_MSB_TEMP"
  }, {
    path: "/ms/msc/SCR_MSC_0001",
    component: _4d520960,
    name: "ms-msc-SCR_MSC_0001"
  }, {
    path: "/ms/msc/SCR_MSC_0002",
    component: _4d35da5e,
    name: "ms-msc-SCR_MSC_0002"
  }, {
    path: "/ms/msc/SCR_MSC_0003",
    component: _4d19ab5c,
    name: "ms-msc-SCR_MSC_0003"
  }, {
    path: "/ms/mse/SCR_MSE_0001",
    component: _4b0da1cc,
    name: "ms-mse-SCR_MSE_0001"
  }, {
    path: "/ms/mse/SCR_MSE_0002",
    component: _4b1bb94d,
    name: "ms-mse-SCR_MSE_0002"
  }, {
    path: "/ms/msg/SCR_MSG_0015",
    component: _829d022a,
    name: "ms-msg-SCR_MSG_0015"
  }, {
    path: "/ms/msg/SCR_MSG_0016",
    component: _8280d328,
    name: "ms-msg-SCR_MSG_0016"
  }, {
    path: "/ms/msg/SCR_MSG_0017",
    component: _8264a426,
    name: "ms-msg-SCR_MSG_0017"
  }, {
    path: "/ms/msg/SCR_MSG_0019",
    component: _822c4622,
    name: "ms-msg-SCR_MSG_0019"
  }, {
    path: "/ms/msg/SCR_MSG_0021",
    component: _7fa40cf4,
    name: "ms-msg-SCR_MSG_0021"
  }, {
    path: "/or/ora/SCR_ORA_0001",
    component: _1cd4a09e,
    name: "or-ora-SCR_ORA_0001"
  }, {
    path: "/or/ora/SCR_ORA_0005",
    component: _1c63e496,
    name: "or-ora-SCR_ORA_0005"
  }, {
    path: "/or/ora/SCR_ORA_0005_S1",
    component: _0ba14290,
    name: "or-ora-SCR_ORA_0005_S1"
  }, {
    path: "/or/ora/SCR_ORA_0006",
    component: _1c47b594,
    name: "or-ora-SCR_ORA_0006"
  }, {
    path: "/or/ora/SCR_ORA_0007",
    component: _1c2b8692,
    name: "or-ora-SCR_ORA_0007"
  }, {
    path: "/or/ora/SCR_ORA_0009",
    component: _1bf3288e,
    name: "or-ora-SCR_ORA_0009"
  }, {
    path: "/or/ora/SCR_ORA_0009I",
    component: _5022d160,
    name: "or-ora-SCR_ORA_0009I"
  }, {
    path: "/or/ora/SCR_ORA_0009P",
    component: _508575e7,
    name: "or-ora-SCR_ORA_0009P"
  }, {
    path: "/or/ora/SCR_ORA_0010",
    component: _19871e62,
    name: "or-ora-SCR_ORA_0010"
  }, {
    path: "/or/ora/SCR_ORA_0011",
    component: _196aef60,
    name: "or-ora-SCR_ORA_0011"
  }, {
    path: "/or/ora/SCR_ORA_0013",
    component: _1932915c,
    name: "or-ora-SCR_ORA_0013"
  }, {
    path: "/or/ora/SCR_ORA_0014",
    component: _1916625a,
    name: "or-ora-SCR_ORA_0014"
  }, {
    path: "/or/orb/cancelCompletePopUp",
    component: _47632078,
    name: "or-orb-cancelCompletePopUp"
  }, {
    path: "/or/orb/ExchangeList",
    component: _f248dc5c,
    name: "or-orb-ExchangeList"
  }, {
    path: "/or/orb/NoCancelList",
    component: _a315c16c,
    name: "or-orb-NoCancelList"
  }, {
    path: "/or/orb/NoExchangeList",
    component: _ece7e11a,
    name: "or-orb-NoExchangeList"
  }, {
    path: "/or/orb/OrBtnCmp",
    component: _71cd3004,
    name: "or-orb-OrBtnCmp"
  }, {
    path: "/or/orb/OrClAdditPyUnit",
    component: _1e1d69ac,
    name: "or-orb-OrClAdditPyUnit"
  }, {
    path: "/or/orb/OrClDlvInfoUnit",
    component: _80321e9a,
    name: "or-orb-OrClDlvInfoUnit"
  }, {
    path: "/or/orb/OrClPdListUnit",
    component: _00efc9d3,
    name: "or-orb-OrClPdListUnit"
  }, {
    path: "/or/orb/OrClRetnInfoUnit",
    component: _ab1a2dc8,
    name: "or-orb-OrClRetnInfoUnit"
  }, {
    path: "/or/orb/OrClRetnPyInfoUnit",
    component: _aeb77d76,
    name: "or-orb-OrClRetnPyInfoUnit"
  }, {
    path: "/or/orb/SCR_ORB_0001",
    component: _ab1dfa22,
    name: "or-orb-SCR_ORB_0001"
  }, {
    path: "/or/orb/SCR_ORB_0002",
    component: _ab01cb20,
    name: "or-orb-SCR_ORB_0002"
  }, {
    path: "/or/orb/SCR_ORB_0003",
    component: _aae59c1e,
    name: "or-orb-SCR_ORB_0003"
  }, {
    path: "/or/orb/SCR_ORB_0004",
    component: _aac96d1c,
    name: "or-orb-SCR_ORB_0004"
  }, {
    path: "/or/orb/SCR_ORB_0005",
    component: _aaad3e1a,
    name: "or-orb-SCR_ORB_0005"
  }, {
    path: "/or/orb/SCR_ORB_0005_S1",
    component: _15694a8c,
    name: "or-orb-SCR_ORB_0005_S1"
  }, {
    path: "/or/orb/SCR_ORB_0006",
    component: _aa910f18,
    name: "or-orb-SCR_ORB_0006"
  }, {
    path: "/or/orb/SCR_ORB_0007",
    component: _aa74e016,
    name: "or-orb-SCR_ORB_0007"
  }, {
    path: "/or/orb/SCR_ORB_0010",
    component: _a7d077e6,
    name: "or-orb-SCR_ORB_0010"
  }, {
    path: "/or/orb/SCR_ORB_0013",
    component: _a77beae0,
    name: "or-orb-SCR_ORB_0013"
  }, {
    path: "/or/orb/SCR_ORB_0014",
    component: _a75fbbde,
    name: "or-orb-SCR_ORB_0014"
  }, {
    path: "/or/orb/SCR_ORB_0015",
    component: _a7438cdc,
    name: "or-orb-SCR_ORB_0015"
  }, {
    path: "/or/orb/SCR_ORB_0016",
    component: _a7275dda,
    name: "or-orb-SCR_ORB_0016"
  }, {
    path: "/or/orb/SCR_ORB_0017",
    component: _a70b2ed8,
    name: "or-orb-SCR_ORB_0017"
  }, {
    path: "/or/orb/SCR_ORB_0018",
    component: _a6eeffd6,
    name: "or-orb-SCR_ORB_0018"
  }, {
    path: "/or/orb/SCR_ORB_0019",
    component: _a6d2d0d4,
    name: "or-orb-SCR_ORB_0019"
  }, {
    path: "/or/orb/SCR_ORB_0021",
    component: _a44a97a6,
    name: "or-orb-SCR_ORB_0021"
  }, {
    path: "/or/orb/SCR_ORB_0023",
    component: _a41239a2,
    name: "or-orb-SCR_ORB_0023"
  }, {
    path: "/or/orb/SCR_ORB_0024",
    component: _a3f60aa0,
    name: "or-orb-SCR_ORB_0024"
  }, {
    path: "/or/orb/SCR_ORB_0025",
    component: _a3d9db9e,
    name: "or-orb-SCR_ORB_0025"
  }, {
    path: "/or/orb/SCR_ORB_0033",
    component: _a0a88864,
    name: "or-orb-SCR_ORB_0033"
  }, {
    path: "/or/orb/SCR_ORB_0036",
    component: _a053fb5e,
    name: "or-orb-SCR_ORB_0036"
  }, {
    path: "/or/orb/SCR_ORB_0037",
    component: _a037cc5c,
    name: "or-orb-SCR_ORB_0037"
  }, {
    path: "/or/orb/SCR_ORB_0050",
    component: _9a29b2ee,
    name: "or-orb-SCR_ORB_0050"
  }, {
    path: "/or/orb/UX_07_055",
    component: _68261ab3,
    name: "or-orb-UX_07_055"
  }, {
    path: "/or/orb/UX_07_125",
    component: _d02467d2,
    name: "or-orb-UX_07_125"
  }, {
    path: "/or/orc/SCR_ORC_0001",
    component: _634c562d,
    name: "or-orc-SCR_ORC_0001"
  }, {
    path: "/or/orc/SCR_ORC_0001_T1",
    component: _52977bdf,
    name: "or-orc-SCR_ORC_0001_T1"
  }, {
    path: "/or/orc/SCR_ORC_0001_T2",
    component: _52a59360,
    name: "or-orc-SCR_ORC_0001_T2"
  }, {
    path: "/or/orc/SCR_ORC_0001_T3",
    component: _52b3aae1,
    name: "or-orc-SCR_ORC_0001_T3"
  }, {
    path: "/or/orc/SCR_ORC_0001_T4",
    component: _52c1c262,
    name: "or-orc-SCR_ORC_0001_T4"
  }, {
    path: "/or/orc/SCR_ORC_0001_T4_P1",
    component: _5d5f450e,
    name: "or-orc-SCR_ORC_0001_T4_P1"
  }, {
    path: "/or/orc/SCR_ORC_0001_T4_P2",
    component: _5d6d5c8f,
    name: "or-orc-SCR_ORC_0001_T4_P2"
  }, {
    path: "/or/orc/SCR_ORC_0003",
    component: _6368852f,
    name: "or-orc-SCR_ORC_0003"
  }, {
    path: "/or/orc/SCR_ORC_0007",
    component: _63a0e333,
    name: "or-orc-SCR_ORC_0007"
  }, {
    path: "/pd/pdm/SCR_PDM_0002",
    component: _164d6f4b,
    name: "pd-pdm-SCR_PDM_0002"
  }, {
    path: "/pd/pdr/SCR_PDR_0001",
    component: _9af01000,
    name: "pd-pdr-SCR_PDR_0001"
  }, {
    path: "/pd/pds/SCR_PDS_0000",
    component: _6b5533bd,
    name: "pd-pds-SCR_PDS_0000"
  }, {
    path: "/pd/pds/SCR_PDS_0005",
    component: _6b9ba942,
    name: "pd-pds-SCR_PDS_0005"
  }, {
    path: "/pd/pds/SCR_PDS_0006",
    component: _6ba9c0c3,
    name: "pd-pds-SCR_PDS_0006"
  }, {
    path: "/pd/pdt/SCR_PDT_0001",
    component: _243e9e7c,
    name: "pd-pdt-SCR_PDT_0001"
  }, {
    path: "/pd/pdt/SCR_PDT_0002",
    component: _244cb5fd,
    name: "pd-pdt-SCR_PDT_0002"
  }, {
    path: "/pd/pdt/SCR_PDT_0003",
    component: _245acd7e,
    name: "pd-pdt-SCR_PDT_0003"
  }, {
    path: "/pd/pdt/SCR_PDT_0004",
    component: _2468e4ff,
    name: "pd-pdt-SCR_PDT_0004"
  }, {
    path: "/py/pya/SCR_PYA_0002",
    component: _ea8af3d0,
    name: "py-pya-SCR_PYA_0002"
  }, {
    path: "/py/pya/SCR_PYA_0003",
    component: _ea6ec4ce,
    name: "py-pya-SCR_PYA_0003"
  }, {
    path: "/py/pya/SCR_PYA_0004",
    component: _ea5295cc,
    name: "py-pya-SCR_PYA_0004"
  }, {
    path: "/py/pya/SCR_PYA_0005",
    component: _ea3666ca,
    name: "py-pya-SCR_PYA_0005"
  }, {
    path: "/py/pya/SCR_PYA_0007",
    component: _e9fe08c6,
    name: "py-pya-SCR_PYA_0007"
  }, {
    path: "/ug/ugc/SCR_UGC_030",
    component: _2bec72bc,
    name: "ug-ugc-SCR_UGC_030"
  }, {
    path: "/ug/ugd/SCR_UGD_0004",
    component: _718b877d,
    name: "ug-ugd-SCR_UGD_0004"
  }, {
    path: "/ug/ugd/SCR_UGD_0007",
    component: _71b5ce00,
    name: "ug-ugd-SCR_UGD_0007"
  }, {
    path: "/ug/ugd/SCR_UGD_0008",
    component: _71c3e581,
    name: "ug-ugd-SCR_UGD_0008"
  }, {
    path: "/ug/ugd/SCR_UGD_0014",
    component: _7340601c,
    name: "ug-ugd-SCR_UGD_0014"
  }, {
    path: "/ug/ugd/SCR_UGD_0019",
    component: _7386d5a1,
    name: "ug-ugd-SCR_UGD_0019"
  }, {
    path: "/ug/ugd/SCR_UGD_0022",
    component: _74d909b9,
    name: "ug-ugd-SCR_UGD_0022"
  }, {
    path: "/ug/ugd/SCR_UGD_0023",
    component: _74e7213a,
    name: "ug-ugd-SCR_UGD_0023"
  }, {
    path: "/ug/ugd/SCR_UGD_0028",
    component: _752d96bf,
    name: "ug-ugd-SCR_UGD_0028"
  }, {
    path: "/ug/ugd/SCR_UGD_00282",
    component: _309cea83,
    name: "ug-ugd-SCR_UGD_00282"
  }, {
    path: "/ug/ugd/SCR_UGD_0029",
    component: _753bae40,
    name: "ug-ugd-SCR_UGD_0029"
  }, {
    path: "/ug/ugd/SCR_UGD_0030",
    component: _7671b356,
    name: "ug-ugd-SCR_UGD_0030"
  }, {
    path: "/ug/ugd/SCR_UGD_0031",
    component: _767fcad7,
    name: "ug-ugd-SCR_UGD_0031"
  }, {
    path: "/ug/ugd/SCR_UGD_0032",
    component: _768de258,
    name: "ug-ugd-SCR_UGD_0032"
  }, {
    path: "/ug/ugd/SCR_UGD_0033",
    component: _769bf9d9,
    name: "ug-ugd-SCR_UGD_0033"
  }, {
    path: "/ug/ugd/SCR_UGD_0033_P1",
    component: _e7087392,
    name: "ug-ugd-SCR_UGD_0033_P1"
  }, {
    path: "/ug/ugd/SCR_UGD_00up",
    component: _2ad126d8,
    name: "ug-ugd-SCR_UGD_00up"
  }, {
    path: "/ug/ugl/SCR_UGL_0001",
    component: _383bdaea,
    name: "ug-ugl-SCR_UGL_0001"
  }, {
    path: "/ug/ugm/SCR_UGM_0001",
    component: _1dd1a3b0,
    name: "ug-ugm-SCR_UGM_0001"
  }, {
    path: "/ug/ugp/SCR_UGP_0001",
    component: _1ba927e2,
    name: "ug-ugp-SCR_UGP_0001"
  }, {
    path: "/ug/ugp/SCR_UGP_0002",
    component: _1bb73f63,
    name: "ug-ugp-SCR_UGP_0002"
  }, {
    path: "/ug/ugp/SCR_UGP_0003",
    component: _1bc556e4,
    name: "ug-ugp-SCR_UGP_0003"
  }, {
    path: "/ug/ugp/SCR_UGP_0003_P1",
    component: _d6036d68,
    name: "ug-ugp-SCR_UGP_0003_P1"
  }, {
    path: "/ug/ugp/SCR_UGP_00030",
    component: _5ce0fffc,
    name: "ug-ugp-SCR_UGP_00030"
  }, {
    path: "/ug/ugp/SCR_UGP_0004",
    component: _1bd36e65,
    name: "ug-ugp-SCR_UGP_0004"
  }, {
    path: "/ug/ugp/SCR_UGP_0005",
    component: _1be185e6,
    name: "ug-ugp-SCR_UGP_0005"
  }, {
    path: "/ug/ugp/SCR_UGP_0007",
    component: _1bfdb4e8,
    name: "ug-ugp-SCR_UGP_0007"
  }, {
    path: "/ug/ugp/SCR_UGP_0007_P1",
    component: _3482fcc8,
    name: "ug-ugp-SCR_UGP_0007_P1"
  }, {
    path: "/ug/ugp/SCR_UGP_0009",
    component: _1c19e3ea,
    name: "ug-ugp-SCR_UGP_0009"
  }, {
    path: "/ug/ugp/SCR_UGP_0010",
    component: _1d4fe900,
    name: "ug-ugp-SCR_UGP_0010"
  }, {
    path: "/ug/ugp/SCR_UGP_0010_P1",
    component: _1cc19ca0,
    name: "ug-ugp-SCR_UGP_0010_P1"
  }, {
    path: "/ug/ugp/SCR_UGP_0012",
    component: _1d6c1802,
    name: "ug-ugp-SCR_UGP_0012"
  }, {
    path: "/ug/ugp/SCR_UGP_0013",
    component: _1d7a2f83,
    name: "ug-ugp-SCR_UGP_0013"
  }, {
    path: "/ug/ugp/SCR_UGP_0014",
    component: _1d884704,
    name: "ug-ugp-SCR_UGP_0014"
  }, {
    path: "/ug/ugp/SCR_UGP_0017",
    component: _1db28d87,
    name: "ug-ugp-SCR_UGP_0017"
  }, {
    path: "/ug/ugp/SCR_UGP_0018",
    component: _1dc0a508,
    name: "ug-ugp-SCR_UGP_0018"
  }, {
    path: "/ug/ugp/SCR_UGP_0019",
    component: _1dcebc89,
    name: "ug-ugp-SCR_UGP_0019"
  }, {
    path: "/ug/ugp/SCR_UGP_0019_P1",
    component: _ceec74f2,
    name: "ug-ugp-SCR_UGP_0019_P1"
  }, {
    path: "/ug/ugp/SCR_UGP_0020",
    component: _1f04c19f,
    name: "ug-ugp-SCR_UGP_0020"
  }, {
    path: "/ds/diy/:id?",
    component: _7fc9065a,
    name: "ds-diy-id"
  }, {
    path: "/ds/dst/:id?",
    component: _2551468b,
    name: "ds-dst-id"
  }, {
    path: "/ds/dsTest/:id?",
    component: _1b1414a1,
    name: "ds-dsTest-id"
  }, {
    path: "/ds/exbt/:id?",
    component: _831b3336,
    name: "ds-exbt-id"
  }, {
    path: "/ds/fb/:id?",
    component: _262e671c,
    name: "ds-fb-id"
  }, {
    path: "/ds/godbin/:id?",
    component: _82d6e02a,
    name: "ds-godbin-id"
  }, {
    path: "/ds/goods/:id?",
    component: _56023b48,
    name: "ds-goods-id"
  }, {
    path: "/ds/goodssub/:id?",
    component: _e4d5bc6c,
    name: "ds-goodssub-id"
  }, {
    path: "/ds/new/:id?",
    component: _57095f66,
    name: "ds-new-id"
  }, {
    path: "/ds/prodGift/:id?",
    component: _fb5a1a72,
    name: "ds-prodGift-id"
  }, {
    path: "/ds/rank/:id?",
    component: _0215b968,
    name: "ds-rank-id"
  }, {
    path: "/ds/recmd/:id?",
    component: _98ec67a6,
    name: "ds-recmd-id"
  }, {
    path: "/ds/recmd2/:id?",
    component: _0ab2706a,
    name: "ds-recmd2-id"
  }, {
    path: "/ds/specialist/:id?",
    component: _21d7ccde,
    name: "ds-specialist-id"
  }, {
    path: "/ds/specialProds/:id?",
    component: _3a1945e3,
    name: "ds-specialProds-id"
  }, {
    path: "/ds/dsBrand/:id?/:brandId?",
    component: _46ca0164,
    name: "ds-dsBrand-id-brandId"
  }, {
    path: "/ds/spexhbt/:id?/:exbtId?",
    component: _180d5980,
    name: "ds-spexhbt-id-exbtId"
  }, {
    path: "/ds/exhCtgr/:cnrId?/:lgCtgrNo?/:mdCtgrNo?/:smCtgrNo?",
    component: _332373ee,
    name: "ds-exhCtgr-cnrId-lgCtgrNo-mdCtgrNo-smCtgrNo"
  }, {
    path: "/",
    redirect: (_to) => {
          return '/ds';
        },
    name: "home"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
